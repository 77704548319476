import * as React from 'react'
import { ReactSVG } from 'react-svg';
import backArrow from '../assets/backArrow.svg'
import pedidos from '../assets/pedidos.svg'
import { Title } from './title'
import restar from '../assets/restar.svg'
import sumar from '../assets/sumar.svg'
import urlServer from '../server'   
import ReactGA from 'react-ga';
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import Spinner from '../components/spinner';
import { Redirect } from 'react-router-dom'
import eliminar from '../assets/eliminar.svg'
import { guardarConexion } from '../guardarConexion';
const MySwal = withReactContent(Swal)

export default class CarritoPedidoDistribuidora extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            edicionesDePedidoFuturoEnCarrito : [],
            backendrows: [],
            loading: false,
            redirect: false,
            timerId:[]
        }
    }

    validarVencimientoToken(mensaje) {
        if (mensaje) {
            if (mensaje === "Authorization has been denied for this request." || mensaje.indexOf("Unexpected token") !== -1) {
                localStorage.removeItem('ddr-auth');
                localStorage.removeItem('ddr-token');
                localStorage.removeItem('is_authenticated');
                localStorage.removeItem('token');
                localStorage.removeItem('infoToken');
                localStorage.removeItem('expires_in');
                localStorage.removeItem('expires_at');
                window.location.reload();
                return;
            }
        }
        return
    }

    componentDidMount = () => {
        var temp = JSON.parse(localStorage.getItem('edPedFutCarrito'));
        this.setState({edicionesDePedidoFuturoEnCarrito: temp ? temp : []})
        this.obtenerCarrito()
    }

    obtenerCarrito = () => {
        ReactGA.event({
            category: 'Pedidos/CargarPedido',
            action: 'Cargar Carrito'
            });

        const url = urlServer + '/api/pedidopasadofuturo/carrito/obtener'
        var data = {
            usuarioId: null
        }

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.token
            }
        }).then((response) => {
            return response.json()
        }).then((result) => {
            this.validarVencimientoToken(result.message);
            var aux = result.map((edicion, index) => {
                return edicion = {
                    ...edicion,
                    cantidadBackup: edicion.cantidad
                }
            })
            this.setState({backendrows: aux})
        }).catch((error) => {
            this.validarVencimientoToken(error.message);
            console.log('error ', error)
        });

    }

    actualizarCarrito = (edicionId, productoId, cantidad, diaSemana, esPedidoFuturo) => {
        const url = urlServer + '/api/pedidopasadofuturo/carrito/actualizar'
        var data = {
            edicionId: edicionId,
            productoId: productoId,
            cantidad: cantidad,
            esPedidoFuturo: esPedidoFuturo,
            diaSemana: diaSemana
        }

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.token
            }
        }).then((response) => {
            if(!this.state.flag) {
            var edPedFutCarrito = this.state.edicionesDePedidoFuturoEnCarrito;
            var objetivo = {
                productoId: productoId,
                edicionId: edicionId ? edicionId : 0
            } 
            if (edPedFutCarrito.find(epfc => epfc.productoId === objetivo.productoId && epfc.edicionId === objetivo.edicionId && epfc.ultimaCarga != 0) && cantidad === -1) {
                let nuevoArray = edPedFutCarrito.filter(epfc => !(epfc.productoId === objetivo.productoId && epfc.edicionId === objetivo.edicionId));
                this.setState({ edicionesDePedidoFuturoEnCarrito: nuevoArray });
            } else if(edPedFutCarrito.find(epfc => epfc.productoId === objetivo.productoId && epfc.edicionId === objetivo.edicionId && epfc.ultimaCarga === 0)&& cantidad === 0) {
                let nuevoArray = edPedFutCarrito.filter(epfc => !(epfc.productoId === objetivo.productoId && epfc.edicionId === objetivo.edicionId));
                this.setState({ edicionesDePedidoFuturoEnCarrito: nuevoArray });
            }
        }
            return response.json();
        }).then((result) => {
            this.validarVencimientoToken(result.message);            
        }).catch((error) => {
            this.validarVencimientoToken(error.message);
            console.log('error ', error)
        })
    }

    vaciarCarrito = () => {
        const url = urlServer + '/api/pedidopasadofuturo/carrito/vaciar'
        const data = {
            usuarioId:  null,
            esCompra: 0,
            puntoVentaId: null
        }
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.token
            }
        }).then((response) => {
            return response.json();
        }).then((result) => {
            this.validarVencimientoToken(result.message);
            this.setState({backendrows: []})
                }).catch((error) => {
            this.validarVencimientoToken(error.message);
            console.log('error al vaciar el carrito, ', error)
        })
    }

    eliminarItemCarrito = (edicionId, productoId, diaSemana, usurioId) => {
        var url = urlServer + '/api/pedidopasadofuturo/carrito/eliminaritem'
        var data = {
            edicionId: edicionId,
            productoId: productoId,
            diaSemana: diaSemana,
            usuarioId: null
        }

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "bearer " + localStorage.token
                }
        }).then((response) => {
            return response
        }).then((result) => {
            this.validarVencimientoToken(result.message);            
        }).catch((error) => {
            this.validarVencimientoToken(error.message);
            console.log('error al quitar item del carrito ', error);
        });
        localStorage.removeItem('edPedFutCarrito');
    }

    enviarPedido = async () => {
        this.setState({loading: true})
        ReactGA.event({
            category: 'Pedidos/CargarPedido',
            action: 'Enviar Pedido'
            });

        const url = urlServer + '/api/pedidopasadofuturo/guardar'
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: 'Bearer ' + localStorage.token,
        }

        var pedidos = this.state.backendrows.filter(x => x.cantidad >= 0).map((ed, index) => {
            const pedido = {
                productoId: ed.productoId,
                edicionId: ed.edicionId,
                edicionFuturaId: null,
                edicionReferenciaId: null,
                cantidad: ed.cantidad,
                diaSemana: ed.diaSemana
            }
            return pedido;
        })

        const data = {
            puntoVentaId: null,
            usuarioId: null,
            pedidos: pedidos
        }
       
        const respuesta = await fetch(url, {
            method: 'POST',
            redirect: 'manual',
            body: JSON.stringify(data),
            headers
        })
        .then(response => {
            return response.json()
        })
        .then(result => {
            this.validarVencimientoToken(result.message);
            let usuarioId = JSON.parse(localStorage.getItem('infoToken')).usuario_id;
            guardarConexion('PEDIDOSDISTRIBUIDORA', usuarioId, {pedidoPasadoContenedorId: result.pedidoPasadoContenedorId, pedidoFuturoContenedorId: result.pedidoFuturoContenedorId})
            this.setState({loading: false})
            MySwal.fire({
                icon: 'success',
                title: 'Pedido realizado con éxito!',
                showConfirmButton: false,
                timer: 1500
            })
            
            this.setState({redirect: true})
        })
        .catch(err => {
            this.validarVencimientoToken(err.message);
            this.setState({loading: false})
            
            console.log(err)
            MySwal.fire({
            icon: 'error',
            title: 'La operacion no pudo completarse, por favor comuniquelo a traves del canal de ayuda.',
            showConfirmButton: false,
            timer: 2000
            })
            
        }) 

        return respuesta
    }

    definirBackGround = (Id) => {
        let elem = {}
        elem = document.getElementById(Id)
        if (elem && elem.value === "") {
          return 'hsla(14, 100%, 53%, 0.6)'
        } else {
          return 'white'
        }
    }

    redireccionar =()=>{
        if(this.state.redirect){
            return <Redirect push to={{
                pathname: "/Pedidos/MisPedidos"
            }} />
        }
    }

    totalPedidoPasado = () => {
        var cantidad = 0;
        this.state.backendrows.map((ed, ind) => {
            if(ed.edicionId && ed.cantidad !== 'cero' && ed.cantidad !== 'empty')
            {
                cantidad += ed.cantidad;
            }
        })
        return cantidad;
    }
   
    totalPedidoFuturo = () => {
        var cantidad = 0;
        this.state.backendrows.map((ed, ind) => {
            if((ed.edicionId === null && ed.cantidad !== 'cero' && ed.cantidad !== 'empty') || (ed.edicionId === null && ed.cantidad === 0))
            {
                if(ed.cantidad === 0){
                    cantidad = 1;
                }
                if(ed.cantidad < 0){
                    cantidad = 0;
                }
                if (ed.cantidad > 0){
                    cantidad += ed.cantidad;
                }
            }
        })
        return cantidad;
    }

    render() {
        return (
            <React.Fragment>
                <div id="backarrow" className="position-fixed back-arrow-box" onClick={this.props.volverDeCarrito}>
                    <ReactSVG src={backArrow} />
                </div>
                <div id='cargarpedido' className="container text-left">
                    <div className="d-flex justify-content-between">
                        <Title classes=""title='Ediciones seleccionadas'/>
                        <div className="position-relative" style={{marginTop: '53px'}}>
                            <ReactSVG src={pedidos} style={{width: '27px', height: '27px', color: '#8E95A5'}} />
                            <div className="position-absolute d-flex justify-content-center align-items-center f-11" 
                                 style={{
                                    right: '-8px',
                                    top: '-8px',
                                    background: '#EA3F3F',
                                    color: 'white',
                                    borderRadius: '50%',
                                    width: '16px',
                                    height: '16px'
                                 }}>
                                { this.state.backendrows.filter(e => e.cantidad >= 0).length }
                            </div>
                        </div>
                    </div>
                    {this.redireccionar()}
                    {this.state.loading === true ? 
                        <Spinner style={{fontSize: '8px'}} />
                    :
                    <div>
                        <div style={{paddingBottom: '95px'}}>
                            {this.state.backendrows.filter(e => e.cantidad > 0 || e.cantidad === 'empty' || e.cantidad === 'cero' || (e.esPedidoFuturo === 1 && e.cantidad === 0)).map((edicion, index) => {
                                return  <div key={index} className="d-flex justify-content-between  days align-items-center"  style={{paddingRight: '6px'}} >
                                        <div className="f-13-5 fw-400" style={{color: '#343435', maxWidth: '66%'}}>
                                            { edicion.descripcion }
                                            {edicion.edicionId ? 
                                                <span style={{color: '#224372', fontWeight:'bold'}}>
                                                    <br/>
                                                    P. Pas.
                                                </span>
                                            :
                                                <span style={{color: '#224372', fontWeight:'bold'}}>
                                                    <br/>
                                                    P. Fut.
                                                </span>
                                            }
                                        </div>
                                        
                                        <div className="d-flex justify-content-between align-items-center" style={{width: '140px'}}>
                                            <div className="d-flex justify-content-center align-items-center" style={{cursor: !edicion.cantidad 
                                            || (this.state.edicionesDePedidoFuturoEnCarrito?.find(obj => obj.ultimaCarga === 0) && edicion.cantidad === 1) ? 'auto' : 'pointer', background: !edicion.cantidad 
                                            || (this.state.edicionesDePedidoFuturoEnCarrito?.find(obj => obj.ultimaCarga === 0) && edicion.cantidad === 1) ? '#FCFCFC' : '#F4F4F4', width: '26px', height: '26px', borderRadius: '50%'}} onClick={() => {
                                                if (!((edicion?.esPedidoFuturo === 1
                                                        && edicion?.cantidad <= 1 
                                                        && this.state.edicionesDePedidoFuturoEnCarrito?.find(obj => obj.ultimaCarga === 0)
                                                     ) ||(edicion?.esPedidoFuturo === 1
                                                        && edicion?.cantidad <= 0 
                                                        && this.state.edicionesDePedidoFuturoEnCarrito?.find(obj => obj.ultimaCarga != 0)))
                                                    )
                                                {
                                                    if ((edicion.edicionId && edicion.cantidad > 0) || (!edicion.edicionId && edicion.cantidad >= 0)) {
                                                        edicion.cantidad--
                                                        let elemento = document.getElementById(edicion.edicionId);
                                                        if ( elemento ) {
                                                            elemento.value = edicion.cantidad;
                                                        } else {
                                                            elemento = document.getElementById(`PF-${edicion.productoId}-${edicion.diaSemana}`);
                                                            elemento.value = edicion.cantidad
                                                        }
                                                        let { backendrows } = this.state
                                                        
                                                        let ind = backendrows.findIndex(e => e.edicionId === edicion.edicionId && e.productoId === edicion.productoId && e.diaSemana === edicion.diaSemana)
                                                        backendrows[ind] = edicion
                                                        this.actualizarCarrito(edicion.edicionId, edicion.productoId, edicion.cantidad, edicion.diaSemana, edicion.esPedidoFuturo)
                                                        this.setState({
                                                            backendrows
                                                        })
                                                    }
                                                }
                                            }}>
                                                <ReactSVG src={restar} style={{color: !edicion.cantidad ? '#EAEAEA' : '#8E95A5', width: '11px'}} />
                                            </div>
                                            &nbsp;
                                            &nbsp;
                                            <div className="f-13-5 fw-400">
                                                <input
                                                    style={{border:'rgb(52, 52, 53) solid 1px', backgroundColor: this.definirBackGround( edicion.edicionId ? edicion.edicionId : `PF-${edicion.productoId}-${edicion.diaSemana}` ) }}
                                                    type='number' 
                                                    autoComplete="off"
                                                    className='input-of-cantidades text-center'
                                                    maxLength='3'
                                                    id={edicion.edicionId ? edicion.edicionId : `PF-${edicion.productoId}-${edicion.diaSemana}`}
                                                    value ={edicion.cantidad === "cero" ? 0 : edicion.cantidad}
                                                    onFocus={(e) => {
                                                        e.target.select()
                                                    }}
                                                    max="9999"
                                                    onInput={(e) => e.target.value = e.target.value.slice(0, 4)}
                                                    onKeyDown={(e) => {
                                                        if(e.key === "Enter") {
                                                            e.currentTarget.blur()
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        let regex = /[^0-9]/g
                                                        if (regex.test(e.target.value)) {
                                                            e.target.value = e.target.value.replace(regex,'')
                                                        }
                                                        
                                                        if(edicion.cantidad === 'cero' || edicion.cantidad === 'empty')
                                                        {
                                                            if(edicion.cantidadBackup !== 0)
                                                            {
                                                                this.actualizarCarrito(edicion.edicionId, edicion.productoId, 0, edicion.diaSemana, edicion.esPedidoFuturo)
                                                            }
                                                            let { backendrows } = this.state;
                                                            let ind = backendrows.findIndex(e => e.edicionId === edicion.edicionId && e.productoId === edicion.productoId && e.diaSemana === edicion.diaSemana);
                                                            if(backendrows[ind].esPedidoFuturo != 1)
                                                            {
                                                                backendrows.splice(ind, 1)
                                                            }
                                                            this.setState({
                                                                backendrows
                                                            })
                                                        }
                                                        else {
                                                            if(edicion.cantidad !== edicion.cantidadBackup)
                                                            {
                                                                edicion.cantidadBackup = edicion.cantidad;
                                                                let { backendrows } = this.state;
                                                                let ind = backendrows.findIndex(e => e.edicionId === edicion.edicionId && e.productoId === edicion.productoId &&e.diaSemana === edicion.diaSemana);
                                                                backendrows[ind] = edicion
                                                                this.setState({
                                                                    backendrows
                                                                })
                                                                this.actualizarCarrito(edicion.edicionId, edicion.productoId, edicion.cantidad, edicion.diaSemana, edicion.esPedidoFuturo)
                                                            }
                                                        }
                                                        
                                                    }}
                                                    onChange={(e) => {
                                                        let regex = /[^0-9]/g
                                                        if (regex.test(e.target.value)) {
                                                            e.target.value = e.target.value.replace(regex,'')
                                                        }
                                                        e.target.value =  e.target.value === "0" ?  e.target.value :  e.target.value.replace(/^(0+)/g, '');
                                                        edicion.cantidad = e.target.value === "" ? 'empty' : e.target.value === "0" ? 'cero' : parseInt(e.target.value);
                                                        let { backendrows } = this.state;
                                                        let ind = backendrows.findIndex(
                                                                    e => e.edicionId === edicion.edicionId 
                                                                         && e.productoId === edicion.productoId 
                                                                         && e.diaSemana === edicion.diaSemana
                                                                    );
                                                        backendrows[ind] = edicion
                                                        this.setState({
                                                            backendrows
                                                        })
                                                    }}
                                                /> 
                                            </div>
                                            &nbsp;
                                            &nbsp;
                                            <div className="d-flex justify-content-center align-items-center" 
                                                 style={{ background: edicion.cantidad === 9999 ? '#FCFCFC' : '#F4F4F4',
                                                          width: '26px',
                                                          height: '26px',
                                                          borderRadius: '50%',
                                                          cursor: 'pointer',
                                                          pointerEvents: edicion.cantidad >= 9999 ? 'none': ''
                                                        }} 
                                                 onClick={() => {
                                                    edicion.cantidad++
                                                    let elemento = document.getElementById(edicion.edicionId);
                                                    if ( elemento ) {
                                                        elemento.value = edicion.cantidad;
                                                    } else {
                                                        elemento = document.getElementById(`PF-${edicion.productoId}-${edicion.diaSemana}`);
                                                        elemento.value = edicion.cantidad
                                                    }
                                                    let { backendrows } = this.state
                                                    let ind = backendrows.findIndex(
                                                                e => e.edicionId === edicion.edicionId 
                                                                     && e.productoId === edicion.productoId 
                                                                     && e.diaSemana === edicion.diaSemana
                                                                )
                                                    backendrows[ind] = edicion
                                                    this.actualizarCarrito(edicion.edicionId, edicion.productoId, edicion.cantidad, edicion.diaSemana, edicion.esPedidoFuturo)
                                                    this.setState({
                                                        backendrows
                                                    })     
                                            }}>
                                                <ReactSVG src={sumar} 
                                                          style={{color: edicion.stockDisponibleAlmacen <= 0 ? '#EAEAEA' : '#8E95A5', 
                                                                  width: '11px', height: '18px'
                                                                }}
                                                />
                                            </div>

                                            &nbsp;
                                            &nbsp;
                                            <div className="d-flex align-items-center">
                                                <ReactSVG src={eliminar}
                                                        style={{
                                                            cursor: 'pointer',
                                                            margin: '3px',
                                                            width: '23px',
                                                            height: '23px',
                                                            color: '#224372',
                                                            background: '#C7E6F9',
                                                            borderRadius: '50%'
                                                        }}
                                                        onClick={() => {
                                                            this.eliminarItemCarrito(edicion.edicionId, edicion.productoId, edicion.diaSemana, null)
                                                            let { backendrows } = this.state
                                                            let ind = backendrows.findIndex(
                                                                        e => e.edicionId === edicion.edicionId
                                                                             && e.productoId === edicion.productoId
                                                                             && e.diaSemana === edicion.diaSemana
                                                                        )
                                                            backendrows.splice(ind, 1);
                                                            this.setState({backendrows});
                                                        }}/>
                                            </div>
                                        </div>
                                    </div>
                                })}

                        </div>

                        {this.state.backendrows.length > 0 ? 
                            <div id="barraPrecio" className="barra-precio" 
                                 style={{
                                        backgroundColor:'white',
                                        color:'#224372',
                                        fontWeight:'bold'
                                    }}>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <span>Total P. Pas: {this.totalPedidoPasado()}</span>
                                        </div>
                                        <div>
                                            <span>Total P. Fut: {this.totalPedidoFuturo()}</span>
                                        </div>
                                        
                                    </div>
                            </div>
                            : null
                        }
                        { Object.keys(this.state.timerId).length === 0 && 
                        <div id="boton-enviar" className="d-flex justify-content-center align-items-center barra-enviar">
                                        
                            {this.state.backendrows.length > 0 ?
                            <div className="d-flex justify-content-center align-items-center" 
                                 onClick={() => {this.vaciarCarrito();}} 
                                 style={{
                                    background: "#224372",
                                    color: "white",
                                    fontSize: "12px",
                                    textAlign: "center",
                                    cursor: "pointer",
                                    borderRadius: "16px",
                                    width: "100px",
                                    height: "33px"
                                 }}>
                                Vaciar Carrito
                            </div>
                            :null}
                            &nbsp;
                            &nbsp;
                            <div className="d-flex justify-content-center align-items-center" onClick={() => {
                                this.props.volverDeCarrito(this.state.backendrows)
                            }
                            
                            } style={{
                                    background: "#224372",
                                    color: "white",
                                    fontSize: "12px",
                                    textAlign: "center",
                                    cursor: "pointer",
                                    borderRadius: "16px",
                                    width: "120px",
                                    height: "33px"
                                }}>
                                Seguir Comprando
                            </div>
                            &nbsp;
                            &nbsp;
                            {this.state.backendrows.length > 0 ?
                            <div className="d-flex justify-content-center align-items-center" 
                                 onClick={() => {
                                    let { backendrows } = this.state
                                    let cantidadPP = 0;
                                    let cantidadPF = 0;
                                    for (let i = 0; i < backendrows.length; i++) 
                                    {
                                        if(backendrows[i].edicionId)
                                        {
                                            cantidadPP = cantidadPP + backendrows[i].cantidad; 
                                        }
                                        else{
                                            cantidadPF = cantidadPF + backendrows[i].cantidad;
                                        }
                                    }
                                    let msj = '';
                                    if(cantidadPP === 1)
                                    {
                                        msj = "Está a punto de realizar un pedido sin devolución"
                                    }
                                    if(cantidadPP > 1)
                                    {
                                        msj = "Está a punto de realizar pedidos sin devolución"
                                    }
                                    if(cantidadPF === 1)
                                    {
                                        if(cantidadPP > 0)
                                            msj += " y un pedido a futuro"
                                        else
                                            msj = "Está a punto de realizar un pedido a futuro"

                                    }
                                    if(cantidadPF > 1)
                                    {
                                        if(cantidadPP > 0)
                                            msj += " y pedidos a futuro"
                                        else
                                            msj = "Está a punto de realizar pedidos a futuro"
                                    }

                                    Swal.fire({
                                        title: '¿Desea realizar este pedido?',
                                        text: msj,
                                        icon: 'warning',
                                        showCancelButton: true,
                                        confirmButtonColor: '#3085d6',
                                        cancelButtonColor: '#d33',
                                        cancelButtonText: 'Cancelar',
                                        confirmButtonText: 'Confirmar Pedido'
                                        }).then((result) => {
                                        if (result.value) {
                                            this.enviarPedido()
                                            localStorage.removeItem('edPedFutCarrito');
                                        }
                                    })                                    
                                    }
                                } 
                                style={{
                                    background: "#224372",
                                    color: "white", 
                                    fontSize: "12px",
                                    textAlign: "center",
                                    cursor: "pointer",
                                    borderRadius: "16px",
                                    width: "110px",
                                    height: "33px"
                                }}>
                                Confirmar Pedido
                            </div>
                            :null}
                        </div>
                        }
                    </div> }
                    
                </div>
            </React.Fragment>
        )
    }
}