import * as React from 'react';
import { Redirect } from 'react-router-dom'
import { ReactSVG } from 'react-svg';
import { Title } from '../components/title'
import restar from '../assets/restar.svg'
import sumar from '../assets/sumar.svg'
import eliminar from '../assets/eliminar.svg'
import tick from '../assets/tick.svg'
import pedidos from '../assets/pedidos.svg'
import backArrow from '../assets/backArrow.svg'
import Spinner from '../components/spinner';
import urlServer from '../server'
import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga';
import CarritoPedidoDistribuidora from '../components/carritopedidodistribuidora';

export default class CargarPedido extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            producto: this.props.props.location.state ? (this.props.props.location.state.producto.ediciones ? {
                ...this.props.props.location.state.producto,
                publicacionTipoId: 1,
            } : this.props.props.location.state.producto) : null,
            loading: false,
            busqueda: '',
            stock: [],
            redirect: false,
            flag: true,
            nroSumaRestaFlag: false,
            cargaActualCeroFlag: false,
            backendrows: this.props.props.location.state && 
                         this.props.props.location.state.producto.ediciones ?
                         this.props.props.location.state.producto.ediciones.map(edicion => {
                            if (!edicion.cantidad && edicion.cantidad !== 0) {
                                edicion.cantidad = 0
                            }
                            return edicion
                         }) : [],
            pedido: [],
            successCC: false,
            seleccionadasFlag: false,
            diasSemana: [
                {
                    dia: 'Lunes',
                    DiaSemana: 2,
                    cantidad: 0,
                },
                {
                    dia: 'Martes',
                    DiaSemana: 3,
                    cantidad: 0,
                },
                {
                    dia: 'Miércoles',
                    DiaSemana: 4,
                    cantidad: 0,
                },
                {
                    dia: 'Jueves',
                    DiaSemana: 5,
                    cantidad: 0,
                },
                {
                    dia: 'Viernes',
                    DiaSemana: 6,
                    cantidad: 0,
                },
                {
                    dia: 'Sábado',
                    DiaSemana: 7,
                    cantidad: 0,
                },
                {
                    dia: 'Domingo',
                    DiaSemana: 1,
                    cantidad: 0,
                },
            ],
            edicionesDiario: [],
            ultimasrows: [],
            siguientesrows: [],
            nroElementosCarrito: 0,
            edicionesDePedidoFuturoEnCarrito: [],
            ultimaCargadeEdicion: null, //ultima carga para guardar en el localstorage
            busquedaTextoMostrarEnInput: '',
            timerId: []
        }
    }

    history = createBrowserHistory()

    validarVencimientoToken(mensaje) {
        if (mensaje) {
            if (mensaje === "Authorization has been denied for this request." || mensaje.indexOf("Unexpected token") !== -1) {
                localStorage.removeItem('ddr-auth');
                localStorage.removeItem('ddr-token');
                localStorage.removeItem('is_authenticated');
                localStorage.removeItem('token');
                localStorage.removeItem('infoToken');
                localStorage.removeItem('expires_in');
                localStorage.removeItem('expires_at');
                window.location.reload();
            }
        }
        return
    }

    componentDidMount() {
        if (!this.state.backendrows || this.state.backendrows.length < 1) {
            this.ediciones();
        }
        document.title = "Cargar Pedido";
    }

    // funciones input inicio

    definirBackGround = (edicion) => {

        if (edicion.cantidad === null) {
            return 'hsla(14, 100%, 53%, 0.6)'
        }
        else {
            return 'white'
        }
    }

    handleValueInput = (cantidadActual) => {
        if (cantidadActual === "") {
            return ""
        } else {
            return cantidadActual
        }
    }

    handlePedidoSinEdicionAnterior = (edicion) => {
        return (edicion.cantidad === 0 && (edicion.cantidadBackup === 0 || edicion.cantidadBackup === null))
                ? this.state.nroElementosCarrito
                : (edicion.cantidad === 0 && edicion.cantidadBackup > 0) 
                    ? this.state.nroElementosCarrito - 1 
                    : (edicion.cantidad > 0 && (edicion.cantidadBackup === 0 || edicion.cantidadBackup === null) 
                        ? this.state.nroElementosCarrito + 1 
                        : this.state.nroElementosCarrito);
    }

    handlePedidoConEdicionAnterior = (edicion) => {
        return (edicion.cantidad === 0 && edicion.cantidadBackup > 0)
                ? this.state.nroElementosCarrito
                : (edicion.cantidad > 0 && (edicion.cantidadBackup === 0 || edicion.cantidadBackup === null) 
                    ? this.state.nroElementosCarrito + 1 
                    : (edicion.cantidad === 0 && edicion.ultimaUltimaCarga > 0 && !this.state.cargaActualCeroFlag) 
                        ? this.state.nroElementosCarrito + 1 : this.state.nroElementosCarrito);
    }

    handleInputOnBlurUltimasEdiciones = (e, edicion) => {

        let regex = /[^0-9]/g
        // Eliminar caracteres no numéricos
        if (regex.test(e.target.value)) {
            e.target.value = e.target.value.replace(regex, '')
        }
        if (e.target.value === "") {
            edicion.cantidad = 0;
            let nroElementosCarrito = this.state.nroElementosCarrito;

            if (edicion.cantidad !== edicion.cantidadBackup) {
                nroElementosCarrito = (edicion.cantidad === 0 || edicion.cantidad === null) && edicion.cantidadBackup > 0 
                                        ? this.state.nroElementosCarrito - 1 
                                        : (edicion.cantidad > 0 && (edicion.cantidadBackup === 0 || edicion.cantidadBackup === null) 
                                            ? this.state.nroElementosCarrito + 1 : this.state.nroElementosCarrito)
                edicion.cantidadBackup = 0;
                this.actualizarCarrito(edicion.edicionId, edicion.cantidad, null)
            }
            let { backendrows } = this.state;
            let ind = backendrows.findIndex(e => e.edicionId === edicion.edicionId);
            backendrows[ind] = edicion
            this.setState({
                backendrows,
                nroElementosCarrito
            })
        }
        else {
            let cargaActualCeroFlag = this.state.cargaActualCeroFlag;
            var nroElementosCarrito = this.state.nroElementosCarrito;
            if(this.state.flag){
                nroElementosCarrito = (edicion.cantidad === 0 || edicion.cantidad === null) && edicion.cantidadBackup > 0
                                        ? this.state.nroElementosCarrito - 1 
                                        : (edicion.cantidad > 0 && (edicion.cantidadBackup === 0 || edicion.cantidadBackup === null) 
                                            ? this.state.nroElementosCarrito + 1 
                                            : this.state.nroElementosCarrito);

                e.target.value = e.target.value === "0" ? e.target.value : e.target.value.replace(/^(0+)/g, '');
                edicion.cantidad = e.target.value === "" || e.target.value < 0 ? null : parseInt(e.target.value);

                if (edicion.cantidad !== edicion.cantidadBackup) {
                    this.actualizarCarrito(edicion.edicionId, edicion.cantidad, null)
                    edicion.cantidadBackup = edicion.cantidad;
                    let { backendrows } = this.state;
                    let ind = backendrows.findIndex(e => e.edicionId === edicion.edicionId);
                    backendrows[ind] = edicion;
                    
                    this.setState({
                        backendrows,
                        nroElementosCarrito
                    })
                }

            } else {//Para pedido futuro:
                if (edicion.cantidad === 0) {
                    cargaActualCeroFlag = true;
                }
                nroElementosCarrito = (edicion.ultimaUltimaCarga != null ? this.handlePedidoConEdicionAnterior(edicion) : this.handlePedidoSinEdicionAnterior(edicion))
                e.target.value = e.target.value === "0" ? e.target.value : e.target.value.replace(/^(0+)/g, '');
                edicion.cantidad = e.target.value === "" || e.target.value < 0 ? null : parseInt(e.target.value);
                
                if (edicion.cantidad > 0 || (edicion.cantidad === 0 && edicion.ultimaUltimaCarga > 0 && !this.state.cargaActualCeroFlag)) {
                    this.actualizarCarrito(edicion.edicionId, edicion.cantidad, null);
                    edicion.cantidadBackup = edicion.cantidad;
                    this.setState({ nroSumaRestaFlag: true });
                } else if (edicion.cantidad === 0) {
                    if (edicion.ultimaUltimaCarga === null) {
                        this.actualizarCarrito(edicion.edicionId, -1, null); // Quitar del carrito
                        edicion.cantidadBackup = 0;
                    } else {
                        edicion.cantidadBackup = edicion.cantidad;
                    }
                } else if (edicion.ultimaUltimaCarga !== null) {
                    this.actualizarCarrito(edicion.edicionId, edicion.cantidad, null);
                    edicion.cantidadBackup = edicion.cantidad;
                }                

                let { backendrows } = this.state;
                let ind = backendrows.findIndex(e => e.edicionId === edicion.edicionId);
                backendrows[ind] = edicion
                
                this.setState({
                    backendrows,
                    nroElementosCarrito,
                    cargaActualCeroFlag
                })
            }
        }
    }

    handleInputOnBlurPedidoConDias = (e, dia, edicionesDiario, index) => {
        let regex = /[^0-9]/g
        
        // Limpiar caracteres no numéricos
        if (regex.test(e.target.value)) {
            e.target.value = e.target.value.replace(regex, '')
        }

        if (e.target.value === "") {
            dia.cantidad = 0;
            let nroElementosCarrito = this.state.nroElementosCarrito;

            if (dia.cantidad !== dia.cantidadBackup) {
                nroElementosCarrito = (dia.cantidad === 0 || dia.cantidad === null) && dia.cantidadBackup > 0 
                                        ? this.state.nroElementosCarrito - 1 
                                        : (dia.cantidad > 0 && (dia.cantidadBackup === 0 || dia.cantidadBackup === null) 
                                            ? this.state.nroElementosCarrito + 1 : this.state.nroElementosCarrito)

                dia.cantidadBackup = 0;
                this.actualizarCarrito(null, dia.cantidad, dia.DiaSemana)
            }

            edicionesDiario[index] = dia;
            this.setState({
                edicionesDiario,
                nroElementosCarrito
            })
        }
        else {
            let nroElementosCarrito = (dia.cantidad === 0 || dia.cantidad === null) && dia.cantidadBackup > 0 
                                        ? this.state.nroElementosCarrito - 1 
                                        : (dia.cantidad > 0 && (dia.cantidadBackup === 0 || dia.cantidadBackup === null) 
                                            ? this.state.nroElementosCarrito + 1 : this.state.nroElementosCarrito)

            e.target.value = e.target.value === "0" ? e.target.value : e.target.value.replace(/^(0+)/g, '');
            dia.cantidad = e.target.value === "" || e.target.value < 0 ? null : parseInt(e.target.value);
            
            if (dia.cantidad !== dia.cantidadBackup) {
                dia.cantidadBackup = dia.cantidad;
                this.actualizarCarrito(null, dia.cantidad, dia.DiaSemana);
                edicionesDiario[index] = dia;

                this.setState({
                    edicionesDiario,
                    nroElementosCarrito
                })
            }
        }
    }
    // funciones input fin


    ediciones = async () => {
        ReactGA.event({
            category: 'Pedidos/CargarPedido',
            action: 'Listar Ediciones'
        });

        this.setState({
            loading: true
        })

        if (this.state.producto) {

            if (this.state.producto.publicacionTipoId !== 2) { // NO DIARIO

                const headers = {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    Authorization: 'Bearer ' + localStorage.token,
                }

                const data = {
                    productoId: this.state.producto.productoId,
                    fechaHasta: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
                    columnaParaOrdenar: "EDICIONFECHA DESC",
                    pageSize: 50
                }

                const url = urlServer + "/api/edicion/buscarediciones/crearpedido"

                const respuesta = await fetch(url, {
                    method: 'POST',
                    redirect: 'manual',
                    body: JSON.stringify(data),
                    headers
                })
                    .then(response => response.json())
                    .then(async result => {
                        this.validarVencimientoToken(result.message);
                        
                        const data = result.map(edicion => {
                            if (!edicion.cantidad && edicion.cantidad !== 0) {
                                edicion.cantidad = 0
                            }
                            return edicion
                        })

                        // Obtengo ediciones cargadas en el carrito
                        const url = urlServer + '/api/pedidopasadofuturo/carrito/obtener'

                        var filtro = {
                            usuarioId: null
                        }
                        fetch(url, {
                            method: 'POST',
                            body: JSON.stringify(filtro),
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": "Bearer " + localStorage.token
                            }
                        }).then((response) => {
                            return response.json();
                        }).then((result) => {
                            this.validarVencimientoToken(result.message);
                            this.setState({ nroElementosCarrito: result.length })

                            var edPedFutCarrito = this.state.edicionesDePedidoFuturoEnCarrito;
                            //var ultimaCargaBackup = 0;
                            var objetivo = {};
                            var ediciones = data.map((ed, index) => {
                                if (ed.prefijoDescripcion.startsWith("Ultima") || ed.prefijoDescripcion.startsWith("Primera")) {
                                    objetivo =
                                    {
                                        productoId: this.state.producto.productoId,
                                        edicionId: ed.edicionId ? ed.edicionId : 0,
                                        ultimaCarga: ed.ultimaCarga ? ed.ultimaCarga : 0
                                    }
                                    this.setState({ ultimaCargadeEdicion: ed.ultimaCarga});
                                }
                                
                                var ind = result.findIndex(x => x.edicionId === ed.edicionId && x.productoId === this.state.producto.productoId)
                                if (ind !== -1) {
                                    ed.cantidad = result[ind].cantidad;
                                    if (!this.state.flag) {
                                        edPedFutCarrito.push(
                                            {
                                                productoId: this.state.producto.productoId, 
                                                edicionId: result[ind].edicionId ? result[ind].edicionId : 0, 
                                                ultimaCarga: objetivo.ultimaCarga ? objetivo.ultimaCarga : 0
                                            }
                                        );
                                        this.setState({ edicionesDePedidoFuturoEnCarrito: edPedFutCarrito });
                                        this.guardarEdPedFutCarritoEnLocalStorage();
                                    }
                                }
                                else {
                                    if (edPedFutCarrito.find(epfc =>
                                        epfc.productoId === objetivo.productoId &&
                                        epfc.edicionId === objetivo.edicionId && ed.cantidad === 0
                                      )) {
                                        let nuevoArray =
                                        edPedFutCarrito.filter(epfc => 
                                            !(epfc.productoId === objetivo.productoId && epfc.edicionId === objetivo.edicionId)
                                          );
                                        this.setState({ edicionesDePedidoFuturoEnCarrito: nuevoArray });
                                        this.guardarEdPedFutCarritoEnLocalStorage();
                                    }
                                    ed.cantidad = 0;
                                }
                                ed = {
                                    ...ed,
                                    cantidadBackup: ed.cantidad
                                }
                                return ed;
                            })

                            //guardo en el objeto que es el siguiente, algunos datos del ultimo 
                            var indUltimo = -1;
                            var indSiguiente = -1;
                            ediciones.forEach((ed, index) => {
                                if (ed.prefijoDescripcion.startsWith("Ultima") || ed.prefijoDescripcion.startsWith("Primera")) {
                                    indUltimo = index;
                                }
                                if (ed.prefijoDescripcion.startsWith("Siguiente")) {
                                    indSiguiente = index;
                                }
                            });

                            if (indSiguiente !== -1 && indUltimo !== -1) {
                                ediciones[indSiguiente].ultimaPrefijoDescripcion = ediciones[indUltimo].prefijoDescripcion;
                                ediciones[indSiguiente].ultimaDescripcion = ediciones[indUltimo].descripcion;
                                ediciones[indSiguiente].ultimaUltimaCarga = ediciones[indUltimo].ultimaCarga;

                                if (ediciones[indSiguiente].cantidad == 0 
                                    && (ediciones[indUltimo].ultimaCarga != null || ediciones[indUltimo].ultimaCarga > 0)
                                    && !this.state.cargaActualCeroFlag) {
                                    ediciones[indSiguiente].cantidad = ediciones[indUltimo].ultimaCarga
                                } else if (ediciones[indSiguiente].cantidad == 0 && ediciones[indUltimo].ultimaCarga == null) {
                                    ediciones[indSiguiente].cantidad = ediciones[indUltimo].cantidad //Para pedido futuro sin edicion anterior
                                }
                            }

                            this.setState({
                                backendrows: ediciones,
                                // rows: res,
                                loading: false
                            })
                        });

                    })
                    .catch(error => {
                        this.validarVencimientoToken(error.message);
                        console.log('error', error)
                        this.setState({
                            loading: false
                        })
                    });
                return respuesta
            }
            else { // DIARIO

                // Obtengo ediciones cargadas en el carrito
                const url = urlServer + '/api/pedidopasadofuturo/carrito/obtener'
                var filtro = {
                    usuarioId: null
                }

                fetch(url, {
                    method: 'POST',
                    body: JSON.stringify(filtro),
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + localStorage.token
                    }
                }).then((response) => {
                    return response.json();
                }).then((result) => {
                    this.validarVencimientoToken(result.message);
                    this.setState({ nroElementosCarrito: result.length })

                    var aux = JSON.parse(JSON.stringify(this.state.diasSemana));
                    var ediciones = aux.map((dia, index) => {
                        var ind = result.findIndex(x => x.productoId === this.state.producto.productoId && x.diaSemana === dia.DiaSemana)
                        if (ind !== -1) {
                            dia.cantidad = result[ind].cantidad;
                        }
                        else {
                            dia.cantidad = 0;
                        }

                        dia = {
                            ...dia,
                            cantidadBackup: dia.cantidad
                        }
                        return dia;
                    })

                    this.setState({
                        edicionesDiario: ediciones,
                        loading: false
                    })
                }).catch((error) => {
                    this.validarVencimientoToken(error.message);
                    console.log('Error al ediciones cargadas en el carrito > ', error);
                });
            }
        }
        this.setState({
            loading: false
        })
    }

    mostrarSiguienteEdicion = (edicion) => {
        return <div style={{ marginBottom: '-30px' }}>
            <div className='d-flex flex-column flex-sm-row' style={{ paddingBottom: '20px' }}>
                <div className='d-flex flex-column f-13-5 fw-500' style={{ color: '#343435', paddingRight: '20px', paddingBottom: '5px' }}>
                    {edicion.prefijoDescripcion + " "}
                </div>
                <div className="d-flex flex-column flex-sm-row f-13-5 fw-500" style={{ color: '#f40000', }}>
                    {edicion.descripcion + (edicion.descripcion.indexOf(edicion.edicionNumeroFecha) !== -1 ? "" : (" " + (edicion.edicionNumeroFecha ? edicion.edicionNumeroFecha : "")))}
                </div>
            </div>
            <div className='d-flex flex-column flex-sm-row'>
                <div className='d-flex flex-column f-13-5 fw-500' style={{ color: '#343435', paddingRight: '20px', paddingBottom: '5px' }}>
                    {edicion.ultimaPrefijoDescripcion + " "}
                </div>
                <div className="flex-column flex-sm-row f-13-5 fw-500" style={{ color: '#343435', paddingRight: '20px' }}>
                    {edicion.ultimaDescripcion + (edicion.ultimaDescripcion.indexOf(edicion.ultimaEdicionNumeroFecha) !== -1 ? "" : (" " + (edicion.ultimaEdicionNumeroFecha ? edicion.ultimaEdicionNumeroFecha : "")))}
                </div>
                {((edicion.ultimaUltimaCarga !== null) && edicion.ultimaUltimaCarga >= 0) &&
                    <div className="flex-column flex-sm-row f-13-5 fw-500" style={{ color: '#343435' }}>
                        {'Carga: ' + edicion.ultimaUltimaCarga}
                    </div>
                }
            </div>
        </div>
    }

    guardarEdPedFutCarritoEnLocalStorage = () => {
        localStorage.setItem('edPedFutCarrito', JSON.stringify(this.state.edicionesDePedidoFuturoEnCarrito));
    }

    obtenerEdPedFutCarritoEnLocalStorage = () => {
        return JSON.parse(localStorage.getItem('edPedFutCarrito'));
    }

    sumarElementoCarritoYFlag = (nroElemCarrito, edicionId) => {
        if(this.verificarEdicionEnCarrito(this.state.producto.productoId,edicionId))
        {
            this.setState({ nroSumaRestaFlag: true });
        }
        var nroElemCarritoTemp = nroElemCarrito
        if (!this.state.nroSumaRestaFlag) {
            nroElemCarritoTemp = nroElemCarrito + 1
            this.setState({ nroSumaRestaFlag: true })
        }
        return nroElemCarritoTemp;
    }

    restarElementoCarritoYFlag = (nroElemCarrito, edicionId) => {
        if(this.verificarEdicionEnCarrito(this.state.producto.productoId,edicionId))
        {
            this.setState({ nroSumaRestaFlag: true });
        }
        var nroElemCarritoTemp = nroElemCarrito
        if (this.state.nroSumaRestaFlag) {
            nroElemCarritoTemp = nroElemCarrito - 1
            this.setState({ nroSumaRestaFlag: false });
        }
        return nroElemCarritoTemp;
    }

    restarElementoConEdicionAnterior = (edicion, nroElementosCarrito) => {
        if (edicion.cantidad > 0) {
            edicion.cantidad--
            edicion.cantidadBackup = edicion.cantidad
            let elemento = document.getElementById(edicion.edicionId);
            if (elemento) {
                elemento.value = edicion.cantidad;
            } else {
                elemento = document.getElementById('PF');
                elemento.value = edicion.cantidad
            }
            let { backendrows } = this.state
            let ind = backendrows.findIndex(e => e.edicionId === edicion.edicionId)
            backendrows[ind] = edicion
            if(edicion.cantidad === 0 && edicion.ultimaUltimaCarga === 0){
                this.actualizarCarrito(edicion.edicionId, -1, null)
            }else {
                this.actualizarCarrito(edicion.edicionId, edicion.cantidad, null)

            }
            this.setState({
                backendrows,
                nroElementosCarrito: this.state.flag
                    ? (edicion.cantidad === 0 ? this.restarElementoCarritoYFlag(nroElementosCarrito, edicion.edicionId) : nroElementosCarrito)
                    : (edicion.cantidad === edicion.ultimaUltimaCarga - 1 ? this.sumarElementoCarritoYFlag(nroElementosCarrito, edicion.edicionId) 
                                                                          : edicion.cantidad === 0 && edicion.ultimaUltimaCarga === 0 ? this.restarElementoCarritoYFlag(nroElementosCarrito, edicion.edicionId) : nroElementosCarrito)
            })
        }
    }
    restarElementoSinEdicionAnterior = (edicion, nroElementosCarrito) => {
        if (edicion.cantidad > 0) {
            edicion.cantidad--
            edicion.cantidadBackup = edicion.cantidad
            let elemento = document.getElementById(edicion.edicionId);
            if (elemento) {
                elemento.value = edicion.cantidad;
            } else {
                elemento = document.getElementById('PF');
                elemento.value = edicion.cantidad
            }
            let { backendrows } = this.state
            let ind = backendrows.findIndex(e => e.edicionId === edicion.edicionId)
            backendrows[ind] = edicion
            this.setState({
                backendrows,
                nroElementosCarrito: this.state.flag
                    ? (edicion.cantidad === 0 ? nroElementosCarrito -1 : nroElementosCarrito)
                    : (edicion.cantidad === 0 ? this.restarElementoCarritoYFlag(nroElementosCarrito, edicion.edicionId) : nroElementosCarrito)
            })

            let valorActualizarCarrito = edicion.cantidad === 0 ? -1 : edicion.cantidad;
            this.actualizarCarrito(edicion.edicionId, valorActualizarCarrito, null)
        } 
    }

    restarElementosPedidoPasado= (edicion) => {
        let nroElementosCarrito = this.state.nroElementosCarrito;
        edicion.cantidad-- ;
        edicion.cantidadBackup = edicion.cantidad;
        let elemento = document.getElementById(edicion.edicionId);
        if (elemento) {
            elemento.value = edicion.cantidad;
        } else {
            elemento = document.getElementById('PF');
            elemento.value = edicion.cantidad;
        }

        let { backendrows } = this.state;
        let ind = backendrows.findIndex(e => e.edicionId === edicion.edicionId)
        
        backendrows[ind] = edicion;
        if (edicion.cantidad === 0){
            this.actualizarCarrito(edicion.edicionId, -1, null)
        } else {
            this.actualizarCarrito(edicion.edicionId, edicion.cantidad, null)
        }

        this.setState({
            backendrows,
            nroElementosCarrito: edicion.cantidad === 0 
                                    ? nroElementosCarrito - 1 
                                    : nroElementosCarrito
        }) 
    }
    verificarEdicionEnCarrito = (edicionId) => {
        var objetivo =
                    {
                        productoId: this.state.producto.productoId,
                        edicionId: edicionId ? edicionId : 0
                    }
        var edsEnCarrito = this.state.edicionesDePedidoFuturoEnCarrito ? this.state.edicionesDePedidoFuturoEnCarrito : [];
        var res = edsEnCarrito.find(epfc => epfc?.productoId === objetivo.productoId && epfc?.edicionId === objetivo.edicionId);
        return res
    }

    actualizarCarrito = (edicionId, cantidad, diaSemana) => {
        const url = urlServer + '/api/pedidopasadofuturo/carrito/actualizar'
        var data = {
            edicionId: edicionId,
            productoId: this.state.producto.productoId,
            cantidad: cantidad,
            esPedidoFuturo: !this.state.flag,
            diaSemana: diaSemana
        }
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.token
            }
        }).then((response) => {
            var edPedFutCarrito = this.state.edicionesDePedidoFuturoEnCarrito;

            var objetivo = 
            {
                productoId: this.state.producto.productoId,
                edicionId: edicionId ? edicionId : 0,
                ultimaCarga: this.state.ultimaCargadeEdicion ? this.state.ultimaCargadeEdicion : 0
            }

            if(!this.state.flag) {

                if (edPedFutCarrito.find(epfc => epfc.productoId === objetivo.productoId && epfc.edicionId === objetivo.edicionId) && cantidad === -1) {
                    let nuevoArray = edPedFutCarrito.filter(epfc => !(epfc.productoId === objetivo.productoId && epfc.edicionId === objetivo.edicionId));
                    this.setState({ edicionesDePedidoFuturoEnCarrito: nuevoArray });
                } else {
                    edPedFutCarrito.push(objetivo);
                    this.setState({ edicionesDePedidoFuturoEnCarrito: edPedFutCarrito });
                    this.guardarEdPedFutCarritoEnLocalStorage();
                }
            }
            return response.json();
        }).then((result) => {
            this.validarVencimientoToken(result.message);
        }).catch((error) => {
            this.validarVencimientoToken(error.message);
            console.log('error ', error)
        })
    }

    goBack = () => {
        this.setState({
            redirect: true,
        })
    }

    redireccionar = () => {
        if (this.state.redirect) {
            if (this.state.successCC) {
                return <Redirect push to={{
                    pathname: "/Tienda/MisPedidos"
                }} />
            }
            return <Redirect push to={{
                pathname: "/Pedidos"
            }} />
        }
    }

    volverDeCarrito = (backendrowsCarrito) => {
        var hayPedFut = false;
        // verifica si hay pedido futuro en el carrito.
        if (backendrowsCarrito.length > 0) {
            hayPedFut = backendrowsCarrito.some(ber => ber.esPedidoFuturo === 1);
        }
            
        this.setState({ seleccionadasFlag: false })

        if(!hayPedFut){
            this.setState({ nroSumaRestaFlag: false })
        }

        this.ediciones();
    }

    render() {

        const { loading, producto, edicionesDiario, seleccionadasFlag, backendrows, nroElementosCarrito } = this.state

        if (!this.props.props.location.state) {
            return <Redirect to="/Pedidos/MisPedidos" />
        }

        return (
            <React.Fragment>
                {
                    seleccionadasFlag ?
                        <CarritoPedidoDistribuidora volverDeCarrito={this.volverDeCarrito} />
                        :
                        <React.Fragment>
                            <div id="backarrow" className="position-fixed back-arrow-box" onClick={this.goBack}>
                                <ReactSVG src={backArrow} />
                            </div>

                            <div id='cargarpedido' className="container text-left">
                                <React.Fragment>
                                    <div className="d-flex justify-content-between">
                                        <Title classes="" title={seleccionadasFlag ? 'Ediciones seleccionadas' : "Cargar Pedido"} />
                                        <div className="position-relative" style={{ marginTop: '53px' }}>
                                            <ReactSVG src={pedidos} 
                                                      style={{ 
                                                            width: '27px',
                                                            height: '27px',
                                                            color: '#8E95A5'
                                                      }} />
                                            <div className="position-absolute d-flex justify-content-center align-items-center f-11" 
                                                 style={{ 
                                                    right: '-8px', 
                                                    top: '-8px', 
                                                    background: '#EA3F3F', 
                                                    color: 'white', 
                                                    borderRadius: '50%',
                                                    width: '16px',
                                                    height: '16px' 
                                                 }}>
                                                {this.state.nroElementosCarrito}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="titulo-producto">
                                        {producto.descripcion}
                                    </div>
                                </React.Fragment>

                                {this.redireccionar()}
                                {loading ?
                                    <Spinner style={{ fontSize: '8px' }} />
                                    :
                                    (<React.Fragment>
                                        {this.state.producto.publicacionTipoId !== 2 ? // NO DIARIO
                                            <div className="pedido" style={{ paddingBottom: '95px' }}>
                                                {/* Pestañas pedidos */}
                                                {this.state.producto.publicacionTieneRelanzamiento ? 
                                                    <div className="tabs d-flex justify-content-between w-100 " 
                                                         style={{ 
                                                            borderBottom: '1px solid gray',
                                                            marginBottom: '18px' 
                                                         }}>
                                                    <div className={"tablinks col-6 text-center" + (this.state.flag ? ' active' : '')} 
                                                         onClick={(e) => { this.setState({ flag: true }) }}>Últimas Ediciones
                                                    </div>
                                                    <div className={"tablinks col-6 text-center" + (!this.state.flag ? ' active' : '')} 
                                                         onClick={(e) => { this.setState({ flag: false }) }}>Siguiente Edición
                                                    </div>
                                                </div> : null
                                                }
                                                <div>
                                                    {/* Campo de busqueda para pedidos */}
                                                    {this.state.flag &&
                                                        <div className="w-100" style={{ marginBottom: '18px' }}>
                                                            <input className="w-100 form-control"
                                                                    type="text"
                                                                    placeholder="Buscar"
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            busqueda: e.target.value.normalize('NFD').replace(/\p{Diacritic}/gu, "").replace(/\./g, ''),
                                                                            busquedaTextoMostrarEnInput: e.target.value
                                                                        })
                                                                    }}
                                                                    value={this.state.busquedaTextoMostrarEnInput} />
                                                        </div>
                                                    }
                                                    {/* Pedidos ultimas ediciones */}
                                                    {backendrows.filter(e => (this.state.flag ? (!e.prefijoDescripcion || e.prefijoDescripcion.startsWith("Ultima")) : (e.prefijoDescripcion.startsWith("Siguiente")))).filter(a => JSON.stringify(Object.values(a)).normalize('NFD').replace(/\p{Diacritic}/gu, "").toLowerCase().indexOf(this.state.busqueda.toLowerCase()) !== -1).map((edicion, index) => {

                                                        return <div key={index} className="box justify-content-between align-items-center days" style={{ padding: "10px", marginTop: "10px" }}>
                                                            {this.state.flag ?
                                                                <div className="f-13-5 fw-500" style={{ color: '#343435', textAlign: 'justify' }}>
                                                                    {edicion.descripcion + (edicion.descripcion.indexOf(edicion.edicionNumeroFecha) !== -1 
                                                                        ? "" : (" " + (edicion.edicionNumeroFecha ? edicion.edicionNumeroFecha : "")))}
                                                                </div> :
                                                                this.mostrarSiguienteEdicion(edicion)
                                                            }
                                                            {/*CONTENEDOR*/}
                                                            <div className="w-100 footerItem align-items-center d-flex" 
                                                                 style={{ justifyContent: "right", paddingTop: "10px" }}
                                                            >
                                                                <div className={`d-flex justify-content-between align-items-center ${!this.state.flag ? 'elemento-botones-input-siguiente-edicion' : 'elemento-botones-input'}`}>
                                                                    {/*RESTAR*/}
                                                                    <div className="d-flex justify-content-center align-items-center"
                                                                        style={{ cursor: 'pointer', background: !edicion.cantidad ? '#FCFCFC' : '#F4F4F4', width: '26px', height: '26px', borderRadius: '50%', padding: '5px' }}
                                                                        onClick={() => {
                                                                            if (!this.state.flag && edicion.cantidad >= 0) {
                                                                                edicion.ultimaUltimaCarga == null 
                                                                                  ? this.restarElementoSinEdicionAnterior(edicion, this.state.nroElementosCarrito)
                                                                                  : this.restarElementoConEdicionAnterior(edicion, this.state.nroElementosCarrito);
                                                                              } else if (this.state.flag && edicion.cantidad > 0) {
                                                                                this.restarElementosPedidoPasado(edicion);
                                                                              }
                                                                            
                                                                        }}>
                                                                        <ReactSVG src={restar} style={{ color: !edicion.cantidad ? '#EAEAEA' : '#8E95A5', width: '11px' }} title="Restar" />
                                                                    </div>
                                                                    {/*INPUT*/}
                                                                    <div className="f-13-5"
                                                                        style={{ marginLeft: '10px', marginRight: '10px' }}>
                                                                        <input style={{ backgroundColor: this.definirBackGround(edicion), border: '#343435 solid 1px' }}
                                                                            type='number'
                                                                            autoComplete="off"
                                                                            className='input-of-cantidades text-center'
                                                                            maxLength='3'
                                                                            id={edicion.edicionId ? edicion.edicionId : 'PF'}
                                                                            key={edicion.edicionId}
                                                                            value={edicion.cantidad}
                                                                            onFocus={(e) => {
                                                                                e.target.select()
                                                                            }}
                                                                            min={"0"}
                                                                            max={"100000"}
                                                                            onInput={(e) => e.target.value = e.target.value.slice(0, 6)}
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === "Enter") {
                                                                                    e.currentTarget.blur()
                                                                                }
                                                                            }}
                                                                            onChange={(e) => {
                                                                                let regex = /[^0-9]/g
                                                                                if (regex.test(e.target.value)) {
                                                                                    e.target.value = e.target.value.replace(regex, '')
                                                                                }
                                                                                e.target.value = e.target.value === "0" ? e.target.value : e.target.value.replace(/^(0+)/g, '');
                                                                                edicion.cantidad = e.target.value === "" || e.target.value < 0 ? null : parseInt(e.target.value);
                                                                                let { backendrows } = this.state;
                                                                                let ind = backendrows.findIndex(e => e.edicionId === edicion.edicionId);
                                                                                backendrows[ind] = edicion
                                                                                this.setState({
                                                                                    backendrows
                                                                                })
                                                                            }}
                                                                            onBlur={(e) => { this.handleInputOnBlurUltimasEdiciones(e, edicion); }}
                                                                        />
                                                                    </div>
                                                                    {/*SUMAR*/}
                                                                    <div className="d-flex justify-content-center align-items-center"
                                                                        style={{ cursor: 'pointer', background: edicion.cantidad === 100000 ? '#FCFCFC' : '#F4F4F4', width: '26px', height: '26px', borderRadius: '50%', padding: '5px', pointerEvents: edicion.cantidad >= 100000 ? 'none' : '' }}
                                                                        onClick={() => {
                                                                            edicion.cantidad++
                                                                            edicion.cantidadBackup = edicion.cantidad
                                                                            let elemento = document.getElementById(edicion.edicionId);
                                                                            if (elemento) {
                                                                                elemento.value = edicion.cantidad;
                                                                            } else {
                                                                                elemento = document.getElementById('PF');
                                                                                elemento.value = edicion.cantidad
                                                                            }

                                                                            let { backendrows } = this.state
                                                                            let ind = backendrows.findIndex(e => e.edicionId === edicion.edicionId)
                                                                            backendrows[ind] = edicion
                                                                            this.actualizarCarrito(edicion.edicionId, edicion.cantidad, null)
                                                                            this.setState({
                                                                                backendrows,
                                                                                nroElementosCarrito: this.state.flag
                                                                                    ? (edicion.cantidad === 1 ? nroElementosCarrito + 1 : nroElementosCarrito)
                                                                                    : (edicion.cantidad === edicion.ultimaUltimaCarga + 1 || edicion.cantidad === 1 ? this.sumarElementoCarritoYFlag(nroElementosCarrito, edicion.edicionId) : nroElementosCarrito)
                                                                            })
                                                                        }}>
                                                                        <ReactSVG src={sumar} style={{ width: '11px', height: '18px', color: '#8E95A5' }} title="Sumar" />
                                                                    </div>
                                                                    { // Comfirmar
                                                                    (!this.state.flag &&
                                                                        edicion.cantidad != null && // porque si borran lo que hay en el input, edicion.cantidad es igual a edicion.ultimaUltimaCarga para los casos de publicaciones sin ediciones anteriores.
                                                                        edicion.cantidad === edicion.ultimaUltimaCarga &&
                                                                        !this.verificarEdicionEnCarrito(edicion.edicionId) &&
                                                                        edicion.ultimaUltimaCarga != 0) ?
                                                                        <div className="d-flex justify-content-center align-items-center"
                                                                            style={{ 
                                                                                cursor: 'pointer', 
                                                                                background: '#C7E6F9', 
                                                                                width: '26px', 
                                                                                height: '26px', 
                                                                                borderRadius: '50%', 
                                                                                padding: '5px', 
                                                                                pointerEvents: '', 
                                                                                margin: '3px',
                                                                                marginLeft: '10px'
                                                                            }}
                                                                            onClick={() => {
                                                                                edicion.cantidadBackup = edicion.cantidad
                                                                                let elemento = document.getElementById(edicion.edicionId);
                                                                                if (elemento) {
                                                                                    elemento.value = edicion.cantidad;
                                                                                } else {
                                                                                    elemento = document.getElementById('PF');
                                                                                    elemento.value = edicion.cantidad
                                                                                }

                                                                                let { backendrows } = this.state
                                                                                let ind = backendrows.findIndex(e => e.edicionId === edicion.edicionId)
                                                                                backendrows[ind] = edicion
                                                                                this.actualizarCarrito(edicion.edicionId, edicion.cantidad, null)
                                                                                this.setState({
                                                                                    backendrows,
                                                                                    nroElementosCarrito: (edicion.cantidad === edicion.ultimaUltimaCarga
                                                                                        && !this.verificarEdicionEnCarrito(edicion.edicionId))
                                                                                        ? this.sumarElementoCarritoYFlag(nroElementosCarrito, edicion.edicionId)
                                                                                        : nroElementosCarrito
                                                                                })
                                                                            }}>
                                                                            <ReactSVG src={tick} 
                                                                                      style={{ 
                                                                                        width: '11px', 
                                                                                        height: '18px', 
                                                                                        color: '#4e80f5'
                                                                                      }} 
                                                                                      title="Confirmar Carga" />
                                                                        </div>
                                                                        :
                                                                        ((
                                                                        !this.state.flag && this.state.nroElementosCarrito > 0
                                                                        && this.verificarEdicionEnCarrito(edicion.edicionId)))
                                                                        ?
                                                                        <div className="d-flex align-items-center">
                                                                            <ReactSVG src={eliminar}
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                    margin: '3px',
                                                                                    width: '26px',
                                                                                    height: '26px',
                                                                                    color: '#224372',
                                                                                    background: '#C7E6F9',
                                                                                    borderRadius: '50%',
                                                                                    marginLeft: '10px'
                                                                                }}
                                                                                title="Quitar del Carrito"
                                                                                onClick={() => {
                                                                                    edicion.cantidadBackup = edicion.cantidad
                                                                                    let elemento = document.getElementById(edicion.edicionId);
                                                                                    if (elemento) {
                                                                                        elemento.value = edicion.cantidad;
                                                                                    } else {
                                                                                        elemento = document.getElementById('PF');
                                                                                        elemento.value = edicion.cantidad
                                                                                    }
                                                                                    let { backendrows } = this.state
                                                                                    let ind = backendrows.findIndex(e => e.edicionId === edicion.edicionId && e.productoId === this.state.producto.productoId)
                                                                                    const valorNegativoParaCargaCero = -1;
                                                                                    backendrows[ind] = edicion
                                                                                    this.setState({
                                                                                        backendrows,
                                                                                        nroElementosCarrito: this.verificarEdicionEnCarrito(edicion.edicionId)
                                                                                            ? this.restarElementoCarritoYFlag(nroElementosCarrito, edicion.edicionId)
                                                                                            : nroElementosCarrito
                                                                                    })
                                                                                    edicion.cantidad = edicion.ultimaUltimaCarga ? edicion.ultimaUltimaCarga : 0;
                                                                                    elemento.value = edicion.cantidad;
                                                                                    this.actualizarCarrito(edicion.edicionId, valorNegativoParaCargaCero, null)
                                                                                }} />
                                                                        </div> 
                                                                        : (!this.state.flag) &&
                                                                        <div className="d-flex align-items-center">
                                                                            <ReactSVG src={eliminar}
                                                                                style={{
                                                                                    margin: '3px',
                                                                                    width: '26px',
                                                                                    height: '26px',
                                                                                    color: 'grey',
                                                                                    background: 'lightgrey',
                                                                                    borderRadius: '50%',
                                                                                    marginLeft: '10px'
                                                                                }} />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })}

                                                </div>
                                            </div> :
                                            <div className="pedido" style={{ paddingBottom: '95px' }}>
                                                <div className="f-16 fw-400 text-center" style={{ marginBottom: '30px' }}>
                                                    Pedido futuro
                                                </div>
                                                <div>
                                                    {this.state.edicionesDiario.map((dia, index) => {
                                                        return <div key={index} className="d-flex justify-content-between align-items-center days" >
                                                            <div className="f-13-5 fw-400" style={{ color: '#343435', maxWidth: '66%' }}>
                                                                {dia.dia}
                                                            </div>
                                                            {/*CONTENEDOR*/}
                                                            <div className="d-flex justify-content-between align-items-center" style={{ width: '88px' }}>
                                                                {/*RESTAR*/}
                                                                <div className="d-flex justify-content-center align-items-center"
                                                                    style={{ cursor: 'pointer', background: dia.cantidad === 0 ? '#FCFCFC' : '#F4F4F4', width: '26px', height: '26px', borderRadius: '50%' }}
                                                                    onClick={() => {
                                                                        if (dia.cantidad > 0) {
                                                                            let { edicionesDiario } = this.state
                                                                            edicionesDiario[index].cantidad--
                                                                            edicionesDiario[index].cantidadBackup--

                                                                            let elemento = document.getElementById(`dia-${dia.DiaSemana}`);
                                                                            if (elemento) {
                                                                                elemento.value = dia.cantidad;
                                                                            }
                                                                            this.actualizarCarrito(null, dia.cantidad, dia.DiaSemana)
                                                                            this.setState({
                                                                                edicionesDiario,
                                                                                nroElementosCarrito: dia.cantidad === 0 ? nroElementosCarrito - 1 : nroElementosCarrito
                                                                            })
                                                                        }
                                                                    }}>
                                                                    <ReactSVG src={restar} style={{ color: dia.cantidad === 0 ? '#EAEAEA' : '#8E95A5', width: '11px' }} />
                                                                </div>
                                                                {/*INPUT*/}
                                                                <div className="f-13-5">
                                                                    <input
                                                                        style={{ backgroundColor: this.definirBackGround(dia), border: '#343435 solid 1px' }}
                                                                        type='number'
                                                                        autoComplete="off"
                                                                        className='input-of-cantidades text-center'
                                                                        maxLength='3'
                                                                        //id={'dia-'+`${dia.DiaSemana}`}
                                                                        id={`dia-${dia.DiaSemana}`}
                                                                        value={dia.cantidad}
                                                                        onFocus={(e) => {
                                                                            e.target.select()
                                                                        }}
                                                                        max="9999"
                                                                        onInput={(e) => e.target.value = e.target.value.slice(0, 4)}
                                                                        onKeyDown={(e) => {
                                                                            if (e.key === "Enter") {
                                                                                e.currentTarget.blur()
                                                                            }
                                                                        }}
                                                                        onChange={(e) => {
                                                                            let regex = /[^0-9]/g
                                                                            if (regex.test(e.target.value)) {
                                                                                e.target.value = e.target.value.replace(regex, '')
                                                                            }
                                                                            e.target.value = e.target.value === "0" ? e.target.value : e.target.value.replace(/^(0+)/g, '');
                                                                            dia.cantidad = e.target.value === "" || e.target.value < 0 ? null : parseInt(e.target.value);
                                                                            edicionesDiario[index].cantidad = dia.cantidad
                                                                            this.setState({
                                                                                edicionesDiario
                                                                            })
                                                                        }}
                                                                        onBlur={(e) => { this.handleInputOnBlurPedidoConDias(e, dia, edicionesDiario); }}
                                                                    />
                                                                </div>
                                                                {/*SUMAR*/}
                                                                <div className="d-flex justify-content-center align-items-center"
                                                                    style={{ 
                                                                        cursor: 'pointer',
                                                                        background: dia.cantidad === 9999 ? '#FCFCFC' : '#F4F4F4',
                                                                        width: '26px',
                                                                        height: '26px',
                                                                        borderRadius: '50%',
                                                                        pointerEvents: dia.cantidad >= 9999 ? 'none' : ''
                                                                    }}
                                                                    onClick={() => {
                                                                        let { edicionesDiario } = this.state
                                                                        edicionesDiario[index].cantidad++
                                                                        edicionesDiario[index].cantidadBackup++
                                                                        let elemento = document.getElementById(`dia-${dia.DiaSemana}`);
                                                                        if (elemento) {
                                                                            elemento.value = dia.cantidad;
                                                                        }
                                                                        this.actualizarCarrito(null, dia.cantidad, dia.DiaSemana)
                                                                        this.setState({
                                                                            edicionesDiario,
                                                                            nroElementosCarrito: dia.cantidad === 1 ? nroElementosCarrito + 1 : nroElementosCarrito
                                                                        })
                                                                    }}>
                                                                    <ReactSVG src={sumar} 
                                                                              style={{ 
                                                                                width: '11px',
                                                                                height: '18px', 
                                                                                color: '#8E95A5'
                                                                              }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })}
                                                </div>
                                            </div>}
                                        {/*VER CARRITO*/}
                                        {this.state.nroElementosCarrito > 0 && (
                                            <div id="boton-enviar" className="d-flex justify-content-center align-items-center barra-enviar">
                                                <div
                                                    className="d-flex justify-content-center align-items-center"
                                                    onClick={() => this.setState({ seleccionadasFlag: true })}
                                                    style={{
                                                        background: "#224372",
                                                        color: "white",
                                                        fontSize: "12px",
                                                        textAlign: "center",
                                                        cursor: "pointer",
                                                        borderRadius: "16px",
                                                        width: "105px",
                                                        height: "33px",
                                                    }}
                                                >
                                                    Ver Carrito
                                                </div>
                                            </div>
                                        )}
                                    </React.Fragment>
                                    )}
                            </div>
                        </React.Fragment>}
            </React.Fragment>
        )
    }
}